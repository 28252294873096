import CustomModal from '@/components/atoms/CustomModal'
import AddEditNotification from '@/components/molecules/Form/AddEditNotification'
import { errors } from '@/constant/errors'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaNotification } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import { setMessage } from '@/store/slices/messageSlice'
import {
    createNotification,
    notificationStore,
    setSelectedNoti,
    updateNotification,
} from '@/store/slices/notificationSlice'
import { AxiosError } from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TModalCompany } from './ModalCreateCompany'

function ModalNotification(props: TModalCompany) {
    const dispatch = useDispatch<AppDispatch>()
    const { selectedNoti } = useSelector(notificationStore)

    const handleClose = () => {
        props.setOpen(false)
        dispatch(setSelectedNoti(null))
    }
    const handleSubmit = async (data: TAllFormValues['AddEditNotification']) => {
        if (selectedNoti?.id) {
            const res = await dispatch(updateNotification({ id: selectedNoti.id, dataUpdate: data }))
            const error = res.payload as AxiosError
            if (error.response) {
                dispatch(
                    setMessage({
                        message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                        type: 'error',
                    }),
                )
                return
            } else {
                dispatch(setMessage({ message: 'お知らせの更新に成功しました！', type: 'success' }))
            }
        } else {
            const res = await dispatch(createNotification(data))
            const error = res.payload as AxiosError
            if (error.response) {
                dispatch(
                    setMessage({
                        message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                        type: 'error',
                    }),
                )
                return
            } else {
                dispatch(setMessage({ message: 'お知らせの作成に成功しました！', type: 'success' }))
            }
        }

        handleClose()
    }

    const defaultValue = {
        name: selectedNoti?.name || '',
        url: selectedNoti?.url || '',
    }

    return (
        <CustomModal title="お知らせ新規作成" open={props.open} onClose={handleClose} width={800}>
            <ReactHookForm validateSchema={schemaNotification} defaultValues={defaultValue}>
                <AddEditNotification onClose={handleClose} onSubmit={handleSubmit} />
            </ReactHookForm>
        </CustomModal>
    )
}

export default ModalNotification
