import { Notification } from '@/openapi'
import style from '@/utils/style'
import { tableConfigure } from '@/utils/tableConfigure'
import { DeleteRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid'
import React from 'react'
import { TPagination } from '../Pagination'
import CustomPagination2 from '../Pagination/Pagination2'

interface TNotificationsTable {
    data: Notification[]
    pagination: Omit<TPagination, 'setRowsPerPage'>
    loading?: boolean
    onEditClick: (cell: GridCellParams) => void
    onDelete: (id: number) => void
}

function NotificationsTable(props: TNotificationsTable) {
    const columnNotificationsTable: GridColumns = [
        {
            field: 'createdDate',
            headerName: '作成日',
            minWidth: 120,
            sortable: false,
            headerClassName: 'fw-600',
            headerAlign: 'left',
            renderCell: ({ row }) => new Date(row.createdDate).toLocaleDateString('en-ZA'),
        },
        {
            field: 'name',
            headerName: '件名',
            minWidth: 500,
            flex: 1,
            headerClassName: 'fw-600',
            sortable: false,
        },
        {
            field: 'actions',
            headerName: '',
            align: 'center',
            minWidth: 100,
            headerClassName: 'fw-600',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ row }) => {
                return (
                    <Button color="inherit" onClick={() => props.onDelete(row.id)}>
                        <DeleteRounded />
                    </Button>
                )
            },
        },
    ]

    return (
        <Box>
            <DataGrid
                {...tableConfigure}
                sx={{
                    ...style.myGrid,
                    minHeight: 100,
                    '&.MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
                autoHeight
                columns={columnNotificationsTable}
                rows={props.data}
                loading={props.loading}
                onCellClick={props.onEditClick}
            />
            <div className="flex-center mt-40">
                <CustomPagination2 {...props.pagination} />
            </div>
        </Box>
    )
}

export default NotificationsTable
