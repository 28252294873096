import { AccentButton } from '@/components/atoms/Button/AccentButton'
import Line from '@/components/atoms/Line'
import SelectField from '@/components/atoms/SelectField'
import AlertDialogSlide from '@/components/molecules/Dialog/confirm'
import NotificationsTable from '@/components/molecules/tables/notifications'
import ModalNotification from '@/components/organisms/Modals/ModalNotification'
import { withLayout } from '@/components/template/Layout'
import { errors } from '@/constant/errors'
import { AppDispatch } from '@/store'
import { setMessage } from '@/store/slices/messageSlice'
import {
    deleteNotification,
    getListNotification,
    notificationStore,
    setSelectedNoti,
} from '@/store/slices/notificationSlice'
import { getTime } from '@/utils/nendo'
import { GridCellParams } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function Notification() {
    const { loading, listNotification, total, reUpdate } = useSelector(notificationStore)
    const dispatch = useDispatch<AppDispatch>()
    const [notiId, setNotiId] = useState<number | null>(null)
    // pagination state
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // modal state
    const [showModal, setShowModal] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const { year } = getTime()
    const [yearValue, setYearValue] = useState(year)

    const yearOptions = Array.from({ length: year - 2022 }, (_, i) => ({
        id: i + 2023,
        value: `${i + 2023}年`,
    })).reverse()

    useEffect(() => {
        dispatch(
            getListNotification({
                year: yearValue,
                offset: page * rowsPerPage,
                limit: rowsPerPage,
            }),
        )
    }, [page, rowsPerPage, reUpdate, yearValue])

    const handleEditClick = (cell: GridCellParams) => {
        if (cell.field === 'actions') return
        dispatch(setSelectedNoti(cell.row))
        setShowModal(true)
    }
    const handleDeleteClick = (id: number) => {
        setNotiId(id)
        setShowDialog(true)
    }

    const handleConfirmDelete = async () => {
        if (notiId || notiId === 0) {
            const res = await dispatch(deleteNotification(notiId))
            if (res.type.includes('rejected')) {
                dispatch(setMessage({ message: errors.ERROR_OCCURRED, type: 'error' }))
            } else {
                dispatch(setMessage({ message: 'お知らせの削除に成功しました！', type: 'success' }))
            }
        }
        setShowDialog(false)
    }

    return (
        <div className="px-40">
            <h3 className="main-header">お知らせ管理</h3>
            <Line />
            <div className="display-flex justify-space-btwn mt-20 mb-20">
                <SelectField
                    menuItems={yearOptions}
                    className="width-200"
                    size="small"
                    value={yearValue}
                    onChange={(e) => setYearValue(e.target.value as number)}
                />
                <AccentButton sx={{ width: 120, height: 40 }} onClick={() => setShowModal(true)}>
                    新規作成
                </AccentButton>
            </div>
            <NotificationsTable
                loading={loading}
                data={listNotification}
                pagination={{ page, setPage, rowsPerPage, total }}
                onEditClick={handleEditClick}
                onDelete={handleDeleteClick}
            />
            {showModal && <ModalNotification open={showModal} setOpen={setShowModal} />}
            {showDialog && (
                <AlertDialogSlide
                    title="このお知らせを削除してもよろしいですか?"
                    open={showDialog}
                    setOpen={setShowDialog}
                    onOk={handleConfirmDelete}
                />
            )}
        </div>
    )
}

export default withLayout(Notification)
